import React from 'react';
import { NavLink } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

import './MainMenu.scss';

const mainMenu = () => {
    return (
            <nav className="main-menu">
                <ul className="main-menu__menu-list">
                    <li id="produkty" className="main-menu__menu-item">
                        <NavLink to="">Produkty</NavLink>
                        <ul className="produkty">
                            <li>
                                <NavLink to="/kuchynske-linky">Kuchynské linky</NavLink>
                            </li>
                            <li>
                                <NavLink to="/vstavane-skrine">Vstavané skrine</NavLink>
                            </li>
                            <li>
                                <NavLink to="/kancelarsky-nabytok">Kancelársky nábytok</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li id="sluzby" className="main-menu__menu-item">
                        <NavLink to="">Služby</NavLink>
                        <ul className="sluzby">
                            <li>
                                <NavLink to="/porezDTD+paskovanie">Porez DTD + páskovanie</NavLink>
                            </li>
                            <li>
                                <NavLink to="/3Dvizualizacia">3D Vizualizácia</NavLink>
                            </li>
                            <li>
                                <NavLink to="/dvere">Dvere</NavLink>
                            </li>
                            <li>
                                <NavLink to="/material">Materiál</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="main-menu__menu-item">
                        <NavLink from="/galeria" to="/galeria/kuchynske-linky">Galéria</NavLink>
                    </li>
                    <li className="main-menu__menu-item">
                        <HashLink to="#kontakt">Kontakt</HashLink>
                    </li>
                </ul>
            </nav>
    );
};

export default mainMenu;