import React from 'react';

import classes from './Footer.module.scss';

const footer = () => (
    <>
        <footer id="kontakt" className={classes.Kontakt}>
        {/*eslint-disable-next-line  */}
        <div className={classes.Kontakt__container + ' ' + "container"}>
                <div className={classes.Kontakt__container__box}>
                    <h1>Kontaktujte nás</h1>
                    <div className={classes.Kontakt__container__box__text}>
                        <a href="tel:+421948330403">
                        + 421 948 330 403
                        </a>
                        <a href="mailto:sibanabytok@gmail.com">
                        sibanabytok@gmail.com
                        </a>
                    </div>
                </div>
                <div className={classes.Kontakt__container__box}>
                    <h1>Najdete nás aj na sociálnych sieťach</h1>
                    <div className={classes.Kontakt__container__box__text}>
                        <a target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/%C5%A0IBA-n%C3%A1bytok-803077759764068/?ref=br_rs">
                            Facebook
                        </a>
                        <a target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/sibanabytok/">
                            Instagram
                        </a>
                    </div>
                </div>
                <div className={classes.Kontakt__container__box}>
                    <h1>Kde sa nachádzame</h1>
                    <div className={classes.Kontakt__container__box__text}>
                        <a target="_blank"
                        rel="noopener noreferrer"
                        href="https://goo.gl/maps/qqUHHKMzoxK2">
                        Púchovská 74, Pruské
                        </a>
                    </div>
                </div>
            <div className={classes.Kontakt__container__box}>
                <hr className={classes.ft}/>
                <p>
                    &copy; Šiba Nábytok, <time>2019</time>
                </p>
            </div>
        </div>   
    </footer>
    </>

);

export default footer;