import React from 'react';

import classes from './Header.module.scss';
import {Link} from 'react-router-dom';
import MainMenu from './MainMenu/MainMenu';
import ToggleMenu from './MainMenu/ToggleMenu/ToggleMenu';
//import classes from './Header.scss';

const header = () => {

    return (
        <header className={classes.Header}>
          <Link className={classes.Header__logo} to="/">
            šiba<span>nábytok</span>
          </Link>
          <ToggleMenu />      
          <MainMenu />
          <div className={classes.Header__contact}>
            <a target="_blank" 
                rel="noopener noreferrer"
                href="https://www.facebook.com/%C5%A0IBA-n%C3%A1bytok-803077759764068/?ref=br_rs">
                <i className={"fab fa-2x fa-facebook-square " + classes.fa2x} aria-hidden="true"></i>
                <span className={classes.tooltip}>fb: ŠIBA nábytok</span>
            </a>
            <a target="_blank" 
                rel="noopener noreferrer"
                href="https://www.instagram.com/sibanabytok/">
                <i className={"fab fa-2x fa-instagram " + classes.fa2x} aria-hidden="true"></i>
                <span className={classes.tooltip}>ig: sibanabytok</span>
            </a>
            <a href="tel:+421948330403">
                <i className={"fas fa-2x fa-phone-square " + classes.fa2x} aria-hidden="true"></i>
                <span className={classes.tooltip}>+421 948 330 403</span>
            </a>
            <a href="mailto:sibanabytok@gmail.com">
                <i className={"fa fa-2x fa-envelope " + classes.fa2x} aria-hidden="true"></i>
                <span className={classes.tooltip}>sibanabytok@gmail.com</span>
            </a>
          </div>
        </header>
    );
};

export default header;