import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';

import './App.scss';
import SibaNabytok from './containers/SibaNabytok/SibaNabytok';
import Footer from './components/Footer/Footer';


class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <SibaNabytok />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
