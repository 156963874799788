import React, {Component} from 'react';

import './ToggleMenu.scss';
import {NavLink} from 'react-router-dom';

class ToggleMenu extends Component {
    
    render () {
        return (
            <div className="navigation">
                <div className="cover"></div>
                <input type="checkbox" className="navigation__checkbox" id="navi-toggle" />
                <label htmlFor="navi-toggle" className="navigation__button">
                    <span className="navigation__icon">&nbsp;</span>
                </label>
    
              <div className="navigation__background">&nbsp;</div>
                <nav className="navigation__nav">
                    <ul className="navigation__list">
                        <li className="navigation__item">
                            <NavLink to="/kuchynske-linky" className="navigation__link">
                             Kuchynské linky
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/vstavane-skrine" className="navigation__link">
                                Vstavané skrine
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/kancelarsky-nabytok" className="navigation__link">
                                Kancelársky nábytok
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/porezDTD+paskovanie" className="navigation__link">
                                Porez DTD + páskovanie
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/3Dvizualizacia" className="navigation__link">
                                3D vizualizácia
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/dvere" className="navigation__link">
                                Dvere
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/material" className="navigation__link">
                                Materiál
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink from="/galeria" to="/galeria/kuchynske-linky" className="navigation__link">
                                Galéria
                            </NavLink>
                        </li>
                        <li className="navigation__item">
                            <NavLink to="/kontakt" className="navigation__link">
                                Kontakt
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default ToggleMenu;