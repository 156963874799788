import React, {Component, Suspense} from 'react';
import {Route} from 'react-router-dom';

import CookieConsent from "react-cookie-consent";
import Header from '../../components/Header/Header';
import Spinner from '../../components/Spinner/Spinner';

const MainPage = React.lazy(() => import('./Pages/MainPage/MainPage'));

const KuchynskeLinky = React.lazy(() => import('./Pages/Produkty/KuchynskeLinky'));
const VstavaneSkrine = React.lazy(() => import('./Pages/Produkty/VstavaneSkrine'));
const KancelarskyNabytok = React.lazy(() => import('./Pages/Produkty/KancelarskyNabytok'));

const PorezDTD = React.lazy(() => import('./Pages/Služby/PorezDTD/PorezDTD'));
const Vizualizacia = React.lazy(() => import('./Pages/Služby/3Dvizualizacia/3Dvizualizacia'));
const Dvere = React.lazy(() => import('./Pages/Služby/Dvere/Dvere'));
const Material = React.lazy(() => import('./Pages/Služby/Material/Material'));

const Galeria = React.lazy(() => import('./Pages/Gallery/Galeria'));

class SibaNabytok extends Component {
    render(){
        
        return (
            <>
                <div className="container">
                    <CookieConsent 
                        buttonText="Rozumiem"
                        cookieName="myAwesomeCookieName2"
                        style={{ background: "#2B373B", fontFamily: "Varela Round", fontSize: "13px", opacity: ".9"}}
                        buttonStyle={{ color: "#2B373B", borderRadius: "3px", fontSize: "16px", cursor: "pointer"}}
                        expires={150}>
                        Tento web používa na personalizáciu obsahu a analýzu návštevnosti súbory cookie. Používaním tohto webu s tým súhlasíte.
                    </CookieConsent>
                    <Header />
                    <Route path="/" exact render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <MainPage/>
                        </Suspense>
                    )}/>
                        
                    <Route path="/kuchynske-linky" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <KuchynskeLinky/>
                        </Suspense>
                    )}/>
                    <Route path="/vstavane-skrine" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <VstavaneSkrine/>
                        </Suspense>
                    )}/>
                    <Route path="/kancelarsky-nabytok" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <KancelarskyNabytok/>
                        </Suspense>
                    )}/>
                        
                    <Route path="/porezDTD+paskovanie" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <PorezDTD/>
                        </Suspense>
                    )}/>
                    <Route path="/3Dvizualizacia" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <Vizualizacia/>
                        </Suspense>
                    )}/>
                    <Route path="/dvere" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <Dvere/>
                        </Suspense>
                    )}/>
                    <Route path="/material" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <Material/>
                        </Suspense>
                    )}/>

                    <Route path="/galeria" render={() => (
                        <Suspense fallback={<div><Spinner/>></div>}>
                            <Galeria/>
                        </Suspense>
                    )}/>
                </div>
            </>
        )
    }
}

export default SibaNabytok;